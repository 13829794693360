import React, {memo, useState} from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CallIcon from '@mui/icons-material/Call';
import './Incoming.less';

const Incoming: React.FC = () => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Backdrop open={open} onClick={handleClose}>
            <div className="Incoming">
                {/*<Box sx={{p: 5}}>*/}
                {/*    <div className="pulse"><Telegram sx={{color: 'white'}} fontSize="large"/></div>*/}
                {/*    <div>Входящий звонок</div>*/}
                {/*</Box>*/}

                <Card sx={{display: 'flex'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <CardContent sx={{flex: '1 0 auto'}}>
                            <Typography component="div" variant="h5">
                                Входящий звонок
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                от Ивана Иванова
                            </Typography>
                        </CardContent>
                    </Box>
                    <Box sx={{p: 3}}>
                        <div className="pulse"><CallIcon sx={{color: 'white'}} fontSize="large"/></div>
                    </Box>
                </Card>
            </div>
        </Backdrop>
    );
}

export default memo(Incoming);