import React from 'react';
import './App.less';
import MessengerContainer from './containers/MessengerContainer';
import Incoming from "./containers/CallContainer/children/Incoming";

function App() {
    return (
        <>
            <MessengerContainer/>
            <Incoming/>
        </>
    );
}

export default App;
