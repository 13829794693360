import React, {memo, useState, useCallback, useEffect} from 'react';
import axios from 'axios';
import './ConversationList.less';

import ConversationItem, {Conversation} from './children/ConversationItem';
import ConversationSearch from './children/ConversationSearch';

const ConversationList: React.FC = () => {
    const [conversations, setConversations] = useState<Conversation[]>([]);

    const getConversations = useCallback(() => {
        axios.get('https://randomuser.me/api/?results=20').then(response => {
            let newConversations = response.data.results.map((result: any) => {
                return {
                    photo: result.picture.large,
                    name: `Имя Фамилия`,
                    text: 'Текст последнего сообщения...'
                };
            });
            setConversations((prevState: Conversation[]) => [...prevState, ...newConversations])
        });
    }, []);

    useEffect(() => {
        getConversations();
    }, [getConversations])

    return (
        <div className='ConversationList'>
            <ConversationSearch/>
            {
                conversations.map(conversation =>
                    <ConversationItem
                        key={conversation.name}
                        data={conversation}
                    />
                )
            }
        </div>
    )
}

export default memo(ConversationList);