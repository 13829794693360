import React, {memo} from 'react';
import './ConversationItem.less';

export interface Conversation {
    photo: string;
    name: string;
    text: string;
}

interface ConversationItemProps {
    data: Conversation;
}

const ConversationItem: React.FC<ConversationItemProps> = ({data: {photo, name, text}}) => {
    return (
        <div className='ConversationItem'>
            <img className='conversation-photo' src={photo} alt='conversation'/>
            <div className='conversation-info'>
                <h1 className='conversation-title'>{name}</h1>
                <p className='conversation-snippet'>{text}</p>
            </div>
        </div>
    )
}

export default memo(ConversationItem);