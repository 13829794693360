import React, {memo} from 'react';
import './MessengerContainer.less';
import ConversationList from './children/ConversationList';
import MessageList from './children/MessageList';

const MessengerContainer: React.FC = () => {
    return (
        <div className='MessengerContainer'>
            <div className='scrollable sidebar'>
                <ConversationList />
            </div>

            <div className='scrollable content'>
                <MessageList />
            </div>
        </div>
    )
}

export default memo(MessengerContainer);