import React, {memo} from 'react';
import moment from 'moment';
import './Message.less';

export interface MessageItem {
    id: number;
    author: string;
    message: string;
    timestamp: number;
}

interface MessageProps {
    isMine: boolean;
    startsSequence: boolean;
    endsSequence: boolean;
    showTimestamp: boolean;
    data: MessageItem;
}

const Message: React.FC<MessageProps> = ({
                                             data,
                                             isMine,
                                             startsSequence,
                                             endsSequence,
                                             showTimestamp
                                         }) => {
    const friendlyTimestamp = moment(data.timestamp).format('LLLL');

    return (
        <div className={[
            'Message',
            `${isMine ? 'mine' : ''}`,
            `${startsSequence ? 'start' : ''}`,
            `${endsSequence ? 'end' : ''}`
        ].join(' ')}>
            {
                showTimestamp &&
                <div className='timestamp'>
                    {friendlyTimestamp}
                </div>
            }

            <div className='bubble-container'>
                <div className='bubble' title={friendlyTimestamp}>
                    {data.message}
                </div>
            </div>
        </div>
    );
}

export default memo(Message);