import React, {memo} from 'react';
import './ConversationSearch.less';

const ConversationSearch = () => {
    return (
        <div className='ConversationSearch'>
            <input
                type='search'
                className='conversation-search-input'
                placeholder='Поиск'
            />
        </div>
    );
}

export default memo(ConversationSearch)